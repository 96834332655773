import React, { useEffect, useState } from 'react';

const TeleprompterIcon = () => {
    const [strokeColor, setStrokeColor] = useState('none');
    useEffect(() => {
        if (window.location.pathname === '/teleprompter') {
            setStrokeColor('#2b59ff');
        }
    }, [window.location.pathname]);

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={strokeColor}>
            <path
                d="M15.625 0H4.375C1.95875 0 0 1.95875 0 4.375V15.625C0 18.0413 1.95875 20 4.375 20H15.625C18.0413 20 20 18.0413 20 15.625V4.375C20 1.95875 18.0413 0 15.625 0ZM18.75 15.625C18.75 17.3509 17.3509 18.75 15.625 18.75H4.375C2.6491 18.75 1.25 17.3509 1.25 15.625V4.375C1.25 2.6491 2.6491 1.25 4.375 1.25H15.625C17.3509 1.25 18.75 2.6491 18.75 4.375V15.625Z"
                fill="#3F3F46"
            />
            <path
                d="M6.25 4.375H5C4.6548 4.375 4.375 4.6548 4.375 5V6.25C4.375 6.5952 4.6548 6.875 5 6.875H6.25C6.5952 6.875 6.875 6.5952 6.875 6.25V5C6.875 4.6548 6.5952 4.375 6.25 4.375ZM6.25 8.75H5C4.6548 8.75 4.375 9.0298 4.375 9.375V10.625C4.375 10.9702 4.6548 11.25 5 11.25H6.25C6.5952 11.25 6.875 10.9702 6.875 10.625V9.375C6.875 9.0298 6.5952 8.75 6.25 8.75ZM6.25 13.125H5C4.6548 13.125 4.375 13.4048 4.375 13.75V15C4.375 15.3452 4.6548 15.625 5 15.625H6.25C6.5952 15.625 6.875 15.3452 6.875 15V13.75C6.875 13.4048 6.5952 13.125 6.25 13.125ZM8.125 5H15.625V6.25H8.125V5ZM8.125 9.375H15.625V10.625H8.125V9.375ZM8.125 13.75H15.625V15H8.125V13.75Z"
                fill="#3F3F46"
            />
        </svg>
    );
};

export default TeleprompterIcon;

import React, { useEffect, useState } from 'react';

const AnalyticsIcon = () => {
    const [strokeColor, setStrokeColor] = useState('none');
    useEffect(() => {
        if (window.location.pathname === '/analytics') {
            setStrokeColor('#2b59ff');
        }
    }, [window.location.pathname]);
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={strokeColor} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 1.5C17.83 1.5 18.5 2.17 18.5 3V17C18.5 17.83 17.83 18.5 17 18.5H3C2.17 18.5 1.5 17.83 1.5 17V3C1.5 2.17 2.17 1.5 3 1.5H17ZM17 0H3C1.34 0 0 1.34 0 3V17C0 18.66 1.34 20 3 20H17C18.66 20 20 18.66 20 17V3C20 1.34 18.66 0 17 0Z"
                fill="#3F3F46"
            />
            <path
                d="M10 15.75C9.59 15.75 9.25 15.41 9.25 15V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V15C10.75 15.41 10.41 15.75 10 15.75ZM6 15.75C5.59 15.75 5.25 15.41 5.25 15V11C5.25 10.59 5.59 10.25 6 10.25C6.41 10.25 6.75 10.59 6.75 11V15C6.75 15.41 6.41 15.75 6 15.75ZM14 15.75C13.59 15.75 13.25 15.41 13.25 15V9C13.25 8.59 13.59 8.25 14 8.25C14.41 8.25 14.75 8.59 14.75 9V15C14.75 15.41 14.41 15.75 14 15.75Z"
                fill="#3F3F46"
            />
        </svg>
    );
};
export default AnalyticsIcon;

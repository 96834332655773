import React, { useEffect, useState } from 'react';

const AppsIcon = () => {
    const [strokeColor, setStrokeColor] = useState('#3F3F46');
    useEffect(() => {
        if (window.location.pathname === '/apps') {
            setStrokeColor('#2b59ff');
        }
    }, [window.location.pathname]);
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1263_7096)">
                <path
                    d="M7.5 9.97408V4.16699C7.5 3.50395 7.76339 2.86807 8.23223 2.39923C8.70107 1.93038 9.33696 1.66699 10 1.66699C10.663 1.66699 11.2989 1.93038 11.7678 2.39923C12.2366 2.86807 12.5 3.50395 12.5 4.16699V5.00283M12.5 10.0016V15.8337C12.5 16.4967 12.2366 17.1326 11.7678 17.6014C11.2989 18.0703 10.663 18.3337 10 18.3337C9.33696 18.3337 8.70107 18.0703 8.23223 17.6014C7.76339 17.1326 7.5 16.4967 7.5 15.8337V14.9878"
                    stroke={strokeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M10.0013 12.5H4.1613C2.78464 12.5 1.66797 11.3808 1.66797 10C1.66797 8.61917 2.78464 7.5 4.1613 7.5H4.99672M10.0013 7.5H15.8301C16.4937 7.49945 17.1304 7.76252 17.6 8.23135C18.0697 8.70017 18.334 9.33637 18.3346 10C18.3346 11.3808 17.2134 12.5 15.8301 12.5H15.0288"
                    stroke={strokeColor}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1263_7096">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AppsIcon;

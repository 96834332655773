// third-party
// assets
import {
    IconUserSearch,
    IconVaccineBottle,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconList,
    IconAlien,
    IconHeart,
    IconMessage2,
    IconBrandChrome,
    IconScreenShare,
    IconWorld,
    IconSitemap
} from '@tabler/icons';
import AnalyticsIcon from 'assets/images/sidebarIcons/AnalyticsIcon';
import DashboardIcon from 'assets/images/sidebarIcons/DashboardIcon';
import DomainIcon from 'assets/images/sidebarIcons/DomainIcon';
import TeleprompterIcon from 'assets/images/sidebarIcons/TeleprompterIcon';
import WebhookIcon from 'assets/images/sidebarIcons/WebhookIcon';

// constant
const icons = {
    IconUserSearch,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconAlien,
    IconHeart,
    IconList,
    IconVaccineBottle,
    IconMessage2,
    IconBrandChrome,
    IconScreenShare,
    IconWorld,
    IconSitemap
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Main Menu',
    type: 'group',
    children: [
        {
            id: 'campaigns',
            title: 'Dashboard',
            // title: <FormattedMessage id="campaigns" />,
            type: 'item',
            url: '/campaigns',
            // icon: icons.IconTemplate,
            icon: DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'teleprompter',
            title: 'Teleprompter',
            type: 'item',
            url: '/teleprompter',
            icon: TeleprompterIcon,
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: '/analytics',
            icon: AnalyticsIcon,
            breadcrumbs: false
        },
        {
            id: 'custom-domain',
            title: 'Custom Domain',
            type: 'item',
            url: '/custom-domain',
            icon: DomainIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;

// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import {
    IconUserSearch,
    IconVaccineBottle,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconList,
    IconAlien,
    IconHeart,
    IconBook
} from '@tabler/icons';
import AppsIcon from 'assets/images/sidebarIcons/AppsIcon';
import WebhookIcon from 'assets/images/sidebarIcons/WebhookIcon';

// constant
const icons = {
    IconUserSearch,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconAlien,
    IconHeart,
    IconList,
    IconVaccineBottle,
    IconBook
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const account = {
    id: 'integrate',
    title: 'Integrate',
    type: 'group',
    children: [
        {
            id: 'webhook',
            title: 'Webhook',
            type: 'item',
            url: '/webhook',
            icon: WebhookIcon,
            breadcrumbs: false
        },
        {
            id: 'apps',
            title: 'Apps',
            type: 'item',
            url: '/apps',
            icon: AppsIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'tutorials',
        //     title: 'Tutotials',
        //     type: 'item',
        //     url: '/tutotials',
        //     icon: icons.IconBook,
        //     breadcrumbs: false
        // }
    ]
};

export default account;

import React, { useEffect, useState } from 'react';

const DomainIcon = () => {
    const [strokeColor, setStrokeColor] = useState('#3F3F46');
    useEffect(() => {
        if (window.location.pathname === '/custom-domain') {
            setStrokeColor('#2b59ff');
        }
    }, [window.location.pathname]);

    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.3346 6.78107C19.717 3.26843 16.1962 0.965585 12.3233 0.901405C8.30545 0.834838 4.65244 3.13832 2.97699 6.78124C1.80206 6.96509 0.9 7.98336 0.9 9.2093V12.7906C0.9 14.0167 1.80224 15.035 2.97736 15.2187C4.62459 18.8015 8.1942 21.1 12.1564 21.1C16.1189 21.1 19.6884 18.8017 21.3355 15.2187C22.5105 15.0349 23.4128 14.0166 23.4128 12.7906V9.2093C23.4128 7.98298 22.5101 6.96447 21.3346 6.78107ZM12.3059 1.95236C15.5999 2.00693 18.6106 3.8694 20.1473 6.75098H4.16421C5.75341 3.75998 8.88161 1.89562 12.3059 1.95236ZM12.1564 20.0489C8.78239 20.0489 5.72643 18.189 4.1646 15.2489H20.1482C18.5865 18.189 15.5306 20.0489 12.1564 20.0489ZM22.3617 12.7906C22.3617 13.5665 21.7303 14.1978 20.9544 14.1978H3.35832C2.58242 14.1978 1.95107 13.5665 1.95107 12.7906V9.2093C1.95107 8.4334 2.58242 7.80205 3.35832 7.80205H20.9544C21.7303 7.80205 22.3617 8.4334 22.3617 9.2093V12.7906Z"
                fill={strokeColor}
                stroke={strokeColor}
                strokeWidth="0.2"
            />
            <path
                d="M7.18994 11.5938L6.4706 9.36719H5.47741L4.75808 11.5938L4.03874 9.36719H2.97656L4.23031 13.053H5.29203L5.97736 11.0456L6.65553 13.053H7.72435L8.9781 9.36719H7.90928L7.18994 11.5938ZM13.3693 11.5938L12.65 9.36719H11.6568L10.9374 11.5938L10.2181 9.36719H9.15597L10.4097 13.053H11.4714L12.1568 11.0456L12.8349 13.053H13.9037L15.1575 9.36719H14.0887L13.3693 11.5938ZM19.5487 11.5938L18.8294 9.36719H17.8362L17.1168 11.5938L16.3975 9.36719H15.3354L16.5891 13.053H17.6508L18.3362 11.0456L19.0143 13.053H20.0831L21.3369 9.36719H20.2681L19.5487 11.5938Z"
                fill={strokeColor}
            />
            <path
                d="M7.18994 11.5938L6.4706 9.36719H5.47741L4.75808 11.5938L4.03874 9.36719H2.97656L4.23031 13.053H5.29203L5.97736 11.0456L6.65553 13.053H7.72435L8.9781 9.36719H7.90928L7.18994 11.5938ZM13.3693 11.5938L12.65 9.36719H11.6568L10.9374 11.5938L10.2181 9.36719H9.15597L10.4097 13.053H11.4714L12.1568 11.0456L12.8349 13.053H13.9037L15.1575 9.36719H14.0887L13.3693 11.5938ZM19.5487 11.5938L18.8294 9.36719H17.8362L17.1168 11.5938L16.3975 9.36719H15.3354L16.5891 13.053H17.6508L18.3362 11.0456L19.0143 13.053H20.0831L21.3369 9.36719H20.2681L19.5487 11.5938Z"
                stroke={strokeColor}
            />
        </svg>
    );
};

export default DomainIcon;

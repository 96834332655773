import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Campaigns = Loadable(lazy(() => import('views/tweetsy/Campaigns')));
const Webhook = Loadable(lazy(() => import('views/tweetsy/webhook')));
const Apps = Loadable(lazy(() => import('views/tweetsy/apps')));
const Teleprompter = Loadable(lazy(() => import('views/tweetsy/teleprompter')));
const CustomDomain = Loadable(lazy(() => import('views/tweetsy/customDomain')));
const Analytics = Loadable(lazy(() => import('views/tweetsy/analytics')));
const Campaign = Loadable(lazy(() => import('views/tweetsy/Campaign')));
const Subscription = Loadable(lazy(() => import('views/tweetsy/subscription')));
const Settings = Loadable(lazy(() => import('views/tweetsy/Settings')));
const Expired = Loadable(lazy(() => import('views/tweetsy/expired')));
const Unauthenticated = Loadable(lazy(() => import('views/tweetsy/unauthenticated')));
const NotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')));
// const ZapierDocs = Loadable(lazy(() => import('views/tweetsy/zapierDocs/ZapierDocs')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/unauthenticated',
            element: <Unauthenticated />
        },
        {
            path: '/expired',
            element: <Expired />
        },

        {
            path: '/campaigns',
            element: <Campaigns />
        },
        {
            path: '/webhook',
            element: <Webhook />
        },
        {
            path: '/teleprompter',
            element: <Teleprompter />
        },
        {
            path: '/custom-domain',
            element: <CustomDomain />
        },
        {
            path: '/analytics',
            element: <Analytics />
        },
        {
            path: '/campaigns/:id',
            element: <Campaign />
        },
        // {
        //     path: '/templates',
        //     element: <Templates />
        // },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/apps',
            element: <Apps />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/*',
            element: <NotFound />
        }
    ]
};

export default MainRoutes;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBBQcCyK6hSPmA4kbteh4rwo_m03lQFhnk',
    authDomain: 'connect-vid.firebaseapp.com',
    projectId: 'connect-vid',
    storageBucket: 'connect-vid.appspot.com',
    messagingSenderId: '580572182793',
    appId: '1:580572182793:web:37900fc0c4e372f1e4fbd8'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
